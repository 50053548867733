import React from "react";
import { TiTick } from 'react-icons/ti';

export default function Projects(){
    return(
        <div className="w-100per h-100per bg-stars p-t40 p-b40" id="projects">
            <div className="fs-40 purple mb-40 flex justify-center align-center">Projects</div>
            <div className="grid grid-cols-2 grid-center md-grid-colms-1 sm-grid-colms-1 mt-100 mb-50 r-gap-5">
                <div className="box-wrap w-400 lg-w-300 md-w-350 sm-w-350 xsm-w-280 h-650 xsm-h-700">
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <div className="tilt-boxes h-100per flex-c r-gap bg-dark dark p-2 br-10 box-sh9 p-rel">
                        <div className="flex-c t-center r-gap p-b10 br-btm">
                            <h2 className="purple">SiteStyles</h2>
                                <img className="h-200" src="/images/projects/sitestyles.jpeg" alt="" />
                        </div>
                        <div className="t-center mt-20">
                            <ul className="mt-10 w-100per t-left">
                                <li className="light fs-14 p-b10"><TiTick />A Components Library made using Next JS.</li>
                                <li className="light fs-14 p-b10"><TiTick />Pre-designed components are shown with Iframes allowing switching of different screens, the container is scrollable for better accessibility.</li>
                                <li className="light fs-14 p-b10"><TiTick />Different animations are implemented for logo and buttons, to showcase creativity. </li>
                            </ul>
                            <button className="hover-br-light w-220 h-50 br-10 bg-dark mt-20 box-sh10 pointer" id="button"><a href="https://sitestyles.prasunroy.live/" rel="noreferrer" target="_blank" className="fs-medium fw-700 purple">Live View</a></button>
                        </div>
                    </div>
                </div>
                <div className="box-wrap w-400 lg-w-300 md-w-350 sm-w-350 xsm-w-280 h-650 xsm-h-700">
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <div className="tilt-boxes h-100per flex-c r-gap bg-dark dark p-2 br-10 box-sh9 p-rel">
                        <div className="flex-c t-center r-gap p-b10 br-btm">
                            <h2 className="purple">Templates</h2>
                                <img className="h-200" src="/images/projects/template.png" alt="" />
                        </div>
                        <div className="t-center mt-20">
                            <ul className="mt-10 w-100per t-left">
                                <li className="light fs-14 p-b10"><TiTick />A CSS library created that is versatile, flexible and offers a variety of classes to create responsive and visually appealing designs with ease.</li>
                                <li className="light fs-14 p-b10"><TiTick />A range of components are created using this CSS such as navbar, footer and more, scalable and adaptable to customization to be easily integrated into projects.</li>
                            </ul>
                            <button className="hover-br-light w-220 h-50 br-10 bg-dark mt-20 box-sh10 pointer" id="button"><a href="https://sitestyles.prasunroy.live/blocks" rel="noreferrer" target="_blank" className="fs-medium fw-700 purple">Live View</a></button>
                        </div>
                    </div>
                </div>
                <div className="box-wrap w-400 lg-w-300 md-w-350 sm-w-350 xsm-w-280 h-600 xsm-h-650">
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <div className="tilt-boxes h-100per flex-c r-gap bg-dark dark p-2 br-10 box-sh9 p-rel">
                    <div className="flex-c t-center r-gap p-b10 br-btm">
                            <h2 className="purple">Personal Portfolio</h2>
                                <img className="h-200" src="/images/projects/portfolio_img.png" alt="" />
                        </div>
                        <div className="t-center mt-20">
                            <ul className="mt-10 w-100per t-left">
                                <li className="light fs-14 p-b10"><TiTick />Personal portfolio created using React.</li>
                                <li className="light fs-14 p-b10"><TiTick />3D animation implemented using Three JS to create a solar system.</li>
                                <li className="light fs-14 p-b10"><TiTick />Playful cards with rockets, images and buttons animations enhances excitement.</li>
                            </ul>
                            <button className="hover-br-light w-220 h-50 br-10 bg-dark mt-20 box-sh10 pointer" id="button"><a href="https://www.pritthadutta.live/" rel="noreferrer" target="_blank" className="fs-medium fw-700 purple">Live View</a></button>
                        </div>
                    </div>
                </div>
                <div className="box-wrap w-400 lg-w-300 md-w-350 sm-w-350 xsm-w-280 h-600 xsm-h-650">
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <span className="box-tilt"></span>
                    <div className="tilt-boxes h-100per flex-c r-gap bg-dark dark p-2 br-10 box-sh9 p-rel">
                        <div className="flex-c t-center r-gap p-b10 br-btm">
                            <h2 className="purple">Hotel Management</h2>
                                <img className="h-200" src="/images/projects/hotel.jpeg" alt="" />
                        </div>
                        <div className="t-center mt-20">
                            <ul className="mt-10 w-100per t-left">
                                <li className="light fs-14 p-b10"><TiTick />Hotel Management System made using React and styled using Bootstrap. </li>
                                <li className="light fs-14 p-b10"><TiTick />Used core concepts of React JS.</li>
                                <li className="light fs-14 p-b10"><TiTick />Integrated backend with frontend to create seamless user experience.</li>
                            </ul>
                            <button className="hover-br-light w-220 h-50 br-10 bg-dark mt-20 box-sh10 pointer" id="button"><a href="https://sithm.netlify.app/Home" rel="noreferrer" target="_blank" className="fs-medium fw-700 purple">Live View</a></button>
                        </div>
                    </div>
                </div>                   
            </div>
        </div>
    );
}