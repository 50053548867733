import React from "react";
import { FaInstagram,FaLinkedin } from 'react-icons/fa';
import { BsGithub,BsArrowRightCircleFill } from 'react-icons/bs';
import { TfiEmail } from 'react-icons/tfi';

export default function About(){
    return(
        <div className="w-100per h-100per bg-stars flex-c align-center justify-center p-t40 p-b40 p-l40 p-r40" id="about">
            <span className="fs-40 purple p-b20">About</span>
            <div className="flex md-flex-c align-center justify-around">
                <div className="w-50per md-w-90per">
                    <p className="fs-23 sm-fs-14 light">
                    Enthusiastic Frontend Developer, currently an undergraduate in Computer Science & Engineering
                    at Siliguri Institute of Technology, West Bengal, India. 
                    Dedicated to crafting imaginative digital realms through the language of code, 
                    and committed to daily growth and innovation!
                    </p>
                    <div className="swipping-btn mt-50">
                        <a href="/#" className="fs-24 md-fs-18 xsm-fs-14 bg-transparent br-none light fw-700 box-sh pointer"><span><BsArrowRightCircleFill /></span>Resume</a>
                    </div>
                </div>
                <div className="card-wrap w-300 sm-w-280 h-400">
                    <div className="card-front w-100per h-100per p-abs br-25 t-center" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/profile.jpg)`}}>
                        <div className="front-info w-100per h-100per overflow-hidden"></div>    
                    </div>
                    <div className="card-back w-100per h-100per p-abs br-25 t-center" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/profile.jpg)`}}>
                        <div className="back-info w-100per h-100per overflow-hidden flex justify-center align-center c-gap">
                            <a href="https://curt.vercel.app/short/3HbRSn0UUV" target="_blank" rel="noreferrer" className="p-rel w-50 h-50 br-50 bg-light blue fs-large flex justify-center align-center hover-transform transition-halfsec"><FaLinkedin /></a>
                            <a href="https://curt.vercel.app/short/m1TIzvCN4t" target="_blank" rel="noreferrer" className="p-rel w-50 h-50 br-50 bg-light dark fs-large flex justify-center align-center hover-transform transition-halfsec"><BsGithub /></a>
                            <a href="https://curt.vercel.app/short/2kfQLsDoID" target="_blank" rel="noreferrer" className="p-rel w-50 h-50 br-50 bg-light hotpink fs-large flex justify-center align-center hover-transform transition-halfsec"><FaInstagram /></a>
                            <a href="mailto:pritthadatta@gmail.com" target="_blank" rel="noreferrer" className="p-rel w-50 h-50 br-50 bg-light red fs-large flex justify-center align-center hover-transform transition-halfsec"><TfiEmail /></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Overflow Reason - todo */}
            {/* <div>
                <img className="w-200 h-200 ufo" src="images/ufo.png" alt="" />
            </div> */}
        </div>
    );
}