import React from "react";
import ThreeD from "./ThreeD";

export default function Home() {
  return (
    <div className="w-100per homeHeight bg-dark light" id="home">
        <p className="homeText purple fs-50 lg-fs-30 md-fs-24 z-index-2 p-abs">
            Hi I'm <span className="bg-gradient1">Prittha</span> <br></br>
            I'm a Frontend Developer<br></br> 
            Let's explore P'world!
        </p>
        <ThreeD />
    </div>
  );
}


