import { useEffect } from "react";
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';

import starsTexture from '../img/stars.jpg';
import sunTexture from '../img/frontend.png';
import mercuryTexture from '../img/html.png';
import venusTexture from '../img/css.png';
import earthTexture from '../img/javascript.png';
import marsTexture from '../img/reactIcon.png';

export default function ThreeD(){
    useEffect(() => {
        space();
    },[]);
    function space(){
        const canvas = document.querySelector('.solarSystem');
        const renderer = new THREE.WebGL1Renderer({canvas});

        const  sizes = {
            width: window.innerWidth,
            height: window.innerHeight,
        }

        renderer.setSize(sizes.width, sizes.height);
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(45,sizes.width/sizes.height,0.1,1000);
        var orbit = new OrbitControls(camera, renderer.domElement);
        orbit.enableZoom=false;
        camera.position.set(-90, 140, 140);
        orbit.update();

        const ambientLight = new THREE.AmbientLight(0x333333);
        scene.add(ambientLight);

        const cubeTextureLoader = new THREE.CubeTextureLoader();
        scene.background = cubeTextureLoader.load([
            starsTexture,
            starsTexture,
            starsTexture,
            starsTexture,
            starsTexture,
            starsTexture
        ]);

        const textureLoader = new THREE.TextureLoader();

        const sunGeo = new THREE.SphereGeometry(16, 30, 30);
        const sunMat = new THREE.MeshBasicMaterial({
            map: textureLoader.load(sunTexture)
        });
        const sun = new THREE.Mesh(sunGeo, sunMat);
        scene.add(sun);

        function createPlanet(size, texture, position) {
            const geo = new THREE.SphereGeometry(size, 30, 30);
            const mat = new THREE.MeshStandardMaterial({
                map: textureLoader.load(texture)
            });
            const mesh = new THREE.Mesh(geo, mat);
            const obj = new THREE.Object3D();
            obj.add(mesh);
            scene.add(obj);
            mesh.position.x = position;
            return {mesh, obj}
        }

        const mercury = createPlanet(7, mercuryTexture, 28);
        const venus = createPlanet(9, venusTexture, 44);
        const earth = createPlanet(10, earthTexture, 62);
        const mars = createPlanet(12, marsTexture, 70);

        const pointLight = new THREE.PointLight(0xFFFFFF, 2, 300);
        scene.add(pointLight);

        function animate() {
        //Self-rotation
            sun.rotateY(0.004);
            mercury.mesh.rotateY(0.004);
            venus.mesh.rotateY(0.002);
            earth.mesh.rotateY(0.02);
            mars.mesh.rotateY(0.018);

        //Around-sun-rotation
            mercury.obj.rotateY(0.04);
            venus.obj.rotateY(0.015);
            earth.obj.rotateY(0.01);
            mars.obj.rotateY(0.008);

        renderer.render(scene, camera);
    }

    renderer.setAnimationLoop(animate);

    window.addEventListener('resize', function() {
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;

        camera.aspect = sizes.width/sizes.height;
        camera.updateProjectionMatrix();
        renderer.setSize(sizes.width, sizes.height);
    });

    }
    return (
        <div className="w-100per homeHeight">
            <canvas className="solarSystem w-100per h-100per">
            </canvas>
        </div>
    );    
}
