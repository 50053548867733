import React from "react";

export default function Skills(){
    return(
        <div className="w-100per bg-stars p-t100 p-b100" id="skills">
            <div className="fs-40 purple mb-40 flex justify-center align-center">Skills</div>
            <div className="grid grid-container--fill grid-col-gap-1 grid-row-gap-2 grid-center lg-marginTop-100 lg-marginBottom-40 ml-60 mr-60 md-margin sm-margin xsm-margin">
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-120 h-150 img-scale-up" src="/images/skills/cpp_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">C++</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-200 h-150 img-scale-up" src="/images/skills/python_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">Python</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/html_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">Html</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/css_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">CSS</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/javascript_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">JavaScript</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-140 img-scale-up" src="/images/skills/reactjs_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">React</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/redux_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">React Redux</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-180 img-scale-up" src="/images/skills/Three.js_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">Three JS</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/bootstrap_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">Bootstrap</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/tailwind_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">Tailwind CSS</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/sass_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">SaSS</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/express_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">Express JS</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-200 h-140 img-scale-up" src="/images/skills/sql_logo.png" alt="" />
                    <h3 className="light fs-medium p-t10 p-b10 fw-700">SQL</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/mongodb_logo.png" alt="" />
                    <h3 className="light fs-medium p-t10 p-b10 fw-700">Mongo DB</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/nextjs_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">NextJs</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/Vue.js_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">Vue.js</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-130 h-150 img-scale-up" src="/images/skills/docker_logo.png" alt="" />
                    <h3 className="light fs-medium p-t10 p-b10 fw-700">Docker</h3>
                </div>
                <div className="w-200 h-250 p-rel flex-c justify-sp align-center p-t20 p-b20 bg-glass br-10 pointer hover-transform-scale-1point1">
                    <img className="w-150 h-150 img-scale-up" src="/images/skills/git_logo.png" alt="" />
                    <h3 className="light fs-medium fw-700">Git</h3>
                </div>
            </div>
        </div>
    );
}