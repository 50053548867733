import React from "react";
import { FaLinkedin } from 'react-icons/fa';
import { BsGithub } from 'react-icons/bs';
import { TfiEmail } from 'react-icons/tfi';

export default function Footer(){
    return(
        <div className="w-100per h-100per bg-stars flex md-flex-c align-center justify-sp p-t10 p-l20 p-r20 p-b12" id="about">
            <div className="light fs-medium md-fs-18">
                <p>© 2023 Prittha Dutta All rights reserved.</p>
            </div>
            <div className="flex c-gap">
                <a href="https://curt.vercel.app/short/3HbRSn0UUV" target="_blank" rel="noreferrer" className="hover-op fs-30 light">
                    <FaLinkedin />
                </a>
                <a href="https://curt.vercel.app/short/m1TIzvCN4t" target="_blank" rel="noreferrer" className="hover-op fs-30 light">
                    <BsGithub />
                </a>
                <a href="mailto:pritthadatta@gmail.com" target="_blank" rel="noreferrer" className="hover-op fs-30 light">
                    <TfiEmail />
                </a>
            </div>
        </div>
    );
}