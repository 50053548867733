import React from "react";

export default function Education(){
    return(
        <div className="w-100per h-100per bg-stars justify-sp p-t20 p-b40 lg-p-b-250 md-p-b-40" id="education">
            <div className="fs-40 purple flex align-center justify-center">Education</div>
            <div className="grid grid-cols-3 lg-grid-colms-2 lg-row-gap-5 md-grid-colms-1 sm-grid-colms-1 grid-center h-100vh md-h-100per lg-marginTop-80 lg-marginBottom-40 ml-60 mr-60 md-margin sm-margin xsm-margin">
                <div className="p-card w-300 h-400 flex-c justify-center bg-glass transition-halfSec p-1 br-20 box-sh">
                    <img className="w-200 h-200 img-transform transition-1Sec" src="/images/education/rocket1.png" alt="" />
                    <div className="transition-halfSec transform-y-20 hover-transform-y-60">
                        <div className="flex-c t-center r-gap fs-18 xsm-fs-14 light">
                            <h3 className="purple">2006-2018</h3>
                            <h3>Holy Child English Academy.</h3>
                            <h3>Indian Certificate of Secondary Education (ICSE).</h3>
                            <h3 className="purple">10th %:- 92.4|A+</h3>
                        </div>
                    </div>    
                </div>
                <div className="p-card w-300 h-400 flex-c justify-center bg-glass transition-halfSec p-1 br-20 box-sh">
                    <img className="w-200 h-160 img-transform transition-1Sec" src="/images/education/rocket3.png" alt=""/>
                    <div className="transition-halfSec transform-y-20 hover-transform-y-60">
                        <div className="flex-c t-center r-gap fs-18 xsm-fs-14 light">
                            <h3 className="purple">2018-2020</h3>
                            <h3>Usha Martin Group of Schools.</h3>
                            <h3>Central Board of Secondary Education (CBSE).</h3>
                            <h3 className="purple">12th %:- 89|A</h3>
                        </div>
                    </div>
                </div>
                <div className="p-card w-300 h-400 flex-c justify-center bg-glass transition-halfSec p-1 br-20 box-sh">
                    <img className="w-200 h-200 img-transform transition-1Sec" src="/images/education/rocket2.png" alt="" />
                    <div className="transition-halfSec transform-y-20 hover-transform-y-60">
                        <div className="flex-c t-center r-gap fs-18 xsm-fs-14 light">
                            <h3 className="purple">2020-2024</h3>
                            <h3>Siliguri Institute of Technology.</h3>
                            <h3>B.Tech in Computer Science and Engineering.</h3>
                            <h3 className="purple">9.25 SGPA</h3>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    );
}