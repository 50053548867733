import React from "react";
import { useState,useEffect } from "react";
import { GiHamburgerMenu } from 'react-icons/gi';

export default function Navbar() {
  const [navOpen, setNavOpen] = useState(false);

  const [shadow, setShadow] = useState(false);
    useEffect(() => {
        const handleShadow = () => {
          if (window.scrollY >= 90) {
            setShadow(true);
          } else {
            setShadow(false);
          }
        };
        window.addEventListener('scroll', handleShadow);
    }, []);

  const toggleNav = ()=>{
    setNavOpen(!navOpen);
  }

  return (
    <header className={`navbar w-100per h-60 md-h-58 bg-stars flex justify-around align-center c-gap-2 p-fixed z-index-3 ${shadow ? "navShadow" : ""}`}>
      <span className="fs-30 md-left"><a className="purple hover-op" href="/#">PD</a></span>
      <div className="hamburger pointer d-block fs-large p-abs purple right-20" onClick={toggleNav}>
        <GiHamburgerMenu />
      </div>
      <ul className="lg-flex justify-center align-center c-gap-2 lg-c-gap md-hidden">
        <li><a className="fs-medium purple hover-nav-items home" href="/#">Home</a></li>
        <li><a className="fs-medium purple about" href="/#about">About</a></li>
        <li><a className="fs-medium purple skills" href="/#skills">Skills</a></li>
        <li><a className="fs-medium purple education" href="/#education">Education</a></li>
        <li><a className="fs-medium purple projects" href="/#projects">Projects</a></li>
        <li><a className="fs-medium purple contact" href="/#contact">Contact</a></li>
      </ul>
      {navOpen && <ul className="w-100per h-fit flex-c align-center justify-sp r-gap-2 small-screen-navbar-transition bg-stars p-t20 p-b20 lg-hidden">
        <li><a className="fs-medium purple home " href="/">Home</a></li>
        <li><a className="fs-medium purple about" href="/#about">About</a></li>
        <li><a className="fs-medium purple skills" href="/#skills">Skills</a></li>
        <li><a className="fs-medium purple education" href="/#education">Education</a></li>
        <li><a className="fs-medium purple projects" href="/#projects">Projects</a></li>
        <li><a className="fs-medium purple contact" href="/#contact">Contact</a></li>
      </ul>}
    </header>
  );
}